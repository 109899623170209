import Text from "./Text";

const Paragraph = Text.withComponent("p");

Paragraph.defaultProps = {
  fontSize: 3,
  fontWeight: 200,
  lineHeight: 1.25,
  color: "black"
};

export default Paragraph;
