import React from "react";
import rehypeReact from "rehype-react";
import Helmet from "react-helmet";
import { themeGet } from "styled-system";
import { injectIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import Header from "../components/Header";
import BannerIllustration from "../components/BannerIllustration";
// import Heading from '../components/Heading'
import Paragraph from "../components/Paragraph";
import StyledLink from "../components/StyledLink";
import SectionCompletion from "../components/SectionCompletion";
import { SmallContainer } from "../components/Grid";
import Text from "../components/Text";
import TextF1 from "../components/TextF1";
import TextF4 from "../components/TextF4";
import TextF5 from "../components/TextF5";
import TextF6 from "../components/TextF6";
import TextF8 from "../components/TextF8";
import Box from "../components/Box";
import Romanize from "../components/Romanize";
import { graphql } from "gatsby";
import styled from "styled-components";

const Heading = styled(TextF1)`
  text-align: center;
  color: white;
  margin: 0;
`;

const Quote = styled(TextF4)`
  color: white;
  margin: 0 0 1rem;
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  font-weight: normal;
`;

const QuoteAuthor = styled(Text)`
  margin: 0;
  font-size: 1.125rem;
  line-height: 1.33;
  color: #d36bd3;
  font-family: ${themeGet("fonts.TTNormsProRegular")};
`;

const IntroText = styled(TextF4)`
  color: white;
  font-family: ${themeGet("fonts.TTNormsProBold")};
  font-weight: normal;
`;

const ArrowSvg = styled(Box)`
  ${/* display: inline-block;
  height: 24px;
  width: 24px;
  background: red;
  background: url(${ArrowUrl});
  margin-left: 1rem;
  transition: 0.1s margin ease-in-out;
  flex: 1 0 auto; */ ""};
`;

const SectionLinkHeading = styled(TextF5)`
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  font-weight: normal;
  color: white;
  margin: 0;
  transition: color 0.1s ease-in-out;
`;

const SectionLink = styled(StyledLink)`
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  padding: 1.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #29264c;
  :hover {
    ${SectionLinkHeading} {
      color: rgba(255, 255, 255, 0.5);
    }
  }
`;

const SectionMeta = styled(TextF8)`
  color: rgba(255, 255, 255, 0.5);
  margin: 0;
  font-family: ${themeGet("fonts.TTNormsProBold")};
  font-weight: normal;
  width: 70px;
  text-align: right;
`;
const StyledTextF6 = styled(TextF6)`
  color: white;
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  font-weight: normal;
  color: rgba(255, 255, 255, 0.5);
  a {
    color: rgba(255, 255, 255, 0.5);
    text-decoration: underline;
    transition: color 0.1s ease;
    :hover {
      color: rgba(255, 255, 255, 1);
      ${""};
    }
  }
`;

const SectionMetaHeader = styled(Box)`
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
`;

const PartTemplate = ({
  data: { allSections, currentPart, allParts },
  intl
}) => {
  const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: {
      h1: Heading,
      p: Paragraph
    }
  }).Compiler;
  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: "globals.courseOverview" }),
      path: intl.formatMessage({ id: "routes.LANDING" })
    },
    {
      title: currentPart.frontmatter.title,
      path: currentPart.frontmatter.path
    }
  ];

  return (
    <Box bg={"purple"}>
      <Helmet title={`${currentPart.frontmatter.title}`}>
        <base target="_blank" />
      </Helmet>
      <Header breadcrumbs={breadcrumbs} />
      <BannerIllustration
        part={currentPart.frontmatter.part}
        subHeading={`${intl.formatMessage({ id: "globals.chapter" })} ${
          currentPart.frontmatter.part
        }/${allParts.totalCount}`}
        heading={currentPart.frontmatter.title}
      />
      <SmallContainer py={5}>
        <Quote>{currentPart.frontmatter.quote}</Quote>
        <QuoteAuthor>{currentPart.frontmatter.quoteAuthor}</QuoteAuthor>
        <IntroText mt={5} mb={4}>
          <FormattedMessage id="partTemplate.intro1" />
          {currentPart.frontmatter.part}
          <FormattedMessage id="partTemplate.intro2" />
        </IntroText>
        <Box>
          <SectionMetaHeader>
            <TextF8 color="rgba(255, 255, 255, 0.5)" m="0">
              <FormattedMessage id="globals.section" />
            </TextF8>
            <TextF8 color="rgba(255, 255, 255, 0.5)" m="0">
              <FormattedMessage id="globals.exercises" />
            </TextF8>
          </SectionMetaHeader>
          {allSections.edges.map(({ node: { frontmatter } }) => {
            if (frontmatter.type === "part") return null;
            return (
              <SectionLink key={frontmatter.path} to={frontmatter.path}>
                <SectionLinkHeading>
                  <Box display="flex" alignItems="center">
                    <div>
                      <Romanize num={frontmatter.section} />
                      {"  "}
                      {frontmatter.title}
                    </div>
                    <ArrowSvg />
                  </Box>
                </SectionLinkHeading>
                <div>
                  <SectionMeta>
                    <SectionCompletion
                      part={frontmatter.part}
                      section={frontmatter.section}
                    />
                  </SectionMeta>
                </div>
              </SectionLink>
            );
          })}
        </Box>
        <StyledTextF6 mt={5} mb={4}>
          <FormattedMessage id="partTemplate.spectrumPart1" />{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={intl.formatMessage({ id: "routes.SPECTRUM" })}
          >
            <FormattedMessage id="partTemplate.spectrum" />
          </a>
          <FormattedMessage id="partTemplate.spectrumPart2" />
        </StyledTextF6>
      </SmallContainer>
    </Box>
  );
};

export default injectIntl(PartTemplate);

export const pageQuery = graphql`
  query sectionsByPart($part: Int!, $path: String!, $lang: String) {
    allParts: allMarkdownRemark(
      sort: { fields: [frontmatter___part], order: ASC }
      filter: { frontmatter: { type: { eq: "part" }, lang: { eq: $lang } } }
    ) {
      totalCount
    }
    allSections: allMarkdownRemark(
      sort: { fields: [frontmatter___section], order: ASC }
      filter: { frontmatter: { part: { eq: $part }, lang: { eq: $lang } } }
    ) {
      totalCount
      edges {
        node {
          htmlAst
          excerpt
          frontmatter {
            path
            title
            part
            type
            section
            lang
          }
        }
      }
    }
    currentPart: markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      frontmatter {
        path
        title
        part
        quote
        quoteAuthor
        bannerImage {
          publicURL
        }
      }
    }
    site: site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`;
